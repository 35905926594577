/*
 * File: SimpleActions.tsx
 * Project: autobuy-back-office
 * File Created: Sunday, 14th January 2024 05:24:39
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Sunday, 14th January 2024 05:49:03
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2024 SAS Olivier Dubois, SAS Olivier Dubois
 */

import LoadingScreen from "Content/LoadingScreen/LoadingScreen"
import { MouseEventHandler, useState } from "react"
import { Button } from "react-bootstrap"
import { BsCheck } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { IoIosSave } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import Style from "Content/Actions/SimpleActions.module.css";
import { FaPencilAlt } from "react-icons/fa";

function SimpleActions(props: {spinner?:boolean, updated?: boolean, disabled: boolean, manageSave?: MouseEventHandler<HTMLButtonElement>, manageUpdate?: MouseEventHandler<HTMLButtonElement>, manageDelete?: MouseEventHandler<HTMLButtonElement>}) {

    return (
        <div className={`${Style.actionHolder}`}>
            {
            props.manageUpdate ?
                <Button disabled={props.disabled} className={`${Style.action}`} variant={"link"} onClick={e => {
                        props.manageUpdate!(e)
                    }} >
                    <FaPencilAlt />
                </Button>
            : null
            }
            {
            props.manageSave ?
                <Button disabled={props.disabled} className={`${Style.action}`} variant={"link"} onClick={e => {
                        props.manageSave!(e)
                    }} >
                    <IoIosSave size={"1.5rem"} />
                </Button>
            : null
            }
            {
            props.manageDelete ?
                <Button disabled={props.disabled} className={`${Style.action}`} variant={"link"} onClick={props.manageDelete} >
                    <RiDeleteBin6Line size={"1.5rem"} />
                </Button>
            : null
            }
            <div className={`${Style.iconHolder}`}>
                {
                    props.spinner ?
                        <LoadingScreen width="2rem" height="2rem" borderWidth="2px"/>
                    : null
                }
                {
                    !props.spinner && props.updated !== undefined ?
                        (
                            props.updated === true ?
                            <BsCheck color="limegreen" size={"2rem"} />
                            : <ImCross color="crimson" />
                        )
                    : null
                }
            </div>
        </div>)
}

export default SimpleActions;
