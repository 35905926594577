/*
 * File: Lights.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 04:43:06
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 7th February 2024 10:13:55
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useState } from "react";
import Styles from "./Ligths.module.css";

type LightProps = {
    date: Date,
    interval?: number
}

const Lights = (props: LightProps) => {
    const [selectedValues, setSelectedValues] = useState<boolean[]>([])

    useEffect(() => {
        const now = Date.now();
        var delta = new Date();
        const selected: boolean[] = []
        delta.setHours(0, 0, 0, 0)
        let fromLastMonth = now - (props.interval ? props.interval*86400000 : 2592000000);
        selected.push(props.date.valueOf() - delta.valueOf() > 0);
        // let fromLast3Month = now - 7776000000;
        selected.push((props.date.valueOf() - fromLastMonth > 0) && (props.date.valueOf() - delta.valueOf() < 0));
        selected.push(props.date.valueOf() - fromLastMonth < 0);
        setSelectedValues([...selected]);
    }, [])

    return (
        <div className={`${Styles.lights}`} >
            <div className={`${Styles.bullet} ${Styles.red} ${selectedValues[2] ? Styles.selected : ""}`}></div>
            <div className={`${Styles.bullet} ${Styles.orange} ${selectedValues[1] ? Styles.selected : ""}`}></div>
            <div className={`${Styles.bullet} ${Styles.green} ${selectedValues[0] ? Styles.selected : ""}`}></div>
        </div>
    )
}

export default Lights