/*
 * File: PasswordCreateInput.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 7th September 2023 11:52:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 7th September 2023 02:09:24
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import translator from "MultiLanguage/TranslationWrapper";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Form } from "react-bootstrap";
import Styles from "Content/Password/PasswordCreateInput.module.css";
import TextField from '@mui/material/TextField';

const PasswordCreateInput = forwardRef((props: {onUpdate: Function}, ref) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

    useImperativeHandle(ref, () => {
        props.onUpdate();
        if (isValid)
            return {password};
        return {password: null};
    }, [isValid, password]);

    const checkValidity = () => {
        if (password.length >= 8 && password !== "" && confirmPassword !== "")
            setIsValid(password === confirmPassword);
        else
            setIsValid(undefined);
    };

    return (
        <div className={`${Styles.formHolder}`}>
            <Form.Group className={`${Styles.formfield}`}>
                {/* <Form.Label className={`${Styles.fieldLabel}`}>
                    {translator.decode("dynamic.password")}
                </Form.Label>
                <Col> */}
                    <TextField type="password" size="small" sx={{width:"100%"}} label={translator.decode("dynamic.password")} required value={password} onChange={(e) => {setPassword(e.target.value); checkValidity()}}/>
                {/* </Col> */}
            </Form.Group>
            <Form.Group className={`${Styles.formfield}`} >
                {/* <Form.Label className={`${Styles.fieldLabel}`}>
                    {translator.decode("dynamic.passwordConfirm")}
                </Form.Label>
                <Col> */}
                    <TextField type="password" size="small" sx={{width:"100%"}} label={translator.decode("dynamic.passwordConfirm")} required onBlur={checkValidity} value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}}/>
                {/* </Col> */}
            </Form.Group>
        </div>
    )
})

export default PasswordCreateInput;
