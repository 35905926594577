/*
 * File: AuthProvider.tsx
 * Project: autobuy-back-office
 * File Created: Monday, 19th June 2023 04:58:33
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 26th June 2023 10:35:34
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { createContext, ReactElement, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "Hooks/useLocalStorage";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserSession from "Utils/UserSession";

export const AuthContext = createContext({ isConnected: false, login: (val: boolean) => {return;}, logout: () => {return;} });

export const AuthProvider = (props: {children: ReactElement<any, any>}) => {
  const [isConnected, setConnexion] = useLocalStorage<boolean>("isConnected", false);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (data: boolean) => {
    setConnexion(data);
  };

  // call this function to sign out logged in user
  const logout = () => {
    setConnexion(false);
    UserSession.signOut();
    navigate(RouterPathResolver.pathLogin.path, { replace: true });
  };

  const value = useMemo(
    () => ({
      isConnected,
      login,
      logout
    }),
    [isConnected]
  );
  return (<AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>);
};

export const useAuth = () => {
  return useContext(AuthContext);
};
