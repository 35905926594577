/*
 * File: ModalSearchUser.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 6th October 2023 12:03:14
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 11th October 2023 10:24:05
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { MouseEventHandler, Fragment, useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import MyModal from "Content/Modals/MyModal";
import translator from "MultiLanguage/TranslationWrapper";
import Styles from "Content/Modals/ModalSearchUser/ModalSearchUser.module.css";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";
import CarService from "Services/Car/CarService";
import FindUser from "Content/FindUser/FindUser";

type ModalProps = {
    show: boolean,
    onClose: MouseEventHandler<HTMLDivElement>,
    closeModal: MouseEventHandler,
    title: string,
    carID: string
};

function ModalSearchUser(props: ModalProps) {
    const [options, setOptions] = useState<{id: string, label: string; disabled: boolean}[]>([]);
    const [value, setValue] = useState<string | null>(null);
    const [spinner, setSpinner] = useState(false);
    const [isOkay, setIsOkay] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [disableBTN, setDisableBTN] = useState<boolean>(true);

    const handleSendCarToClient = () => {
        setDisableBTN(true);
        setSpinner(true);
        CarService.getSendCarIntelToClient(props.carID, value!)
        .then((res: any) => {
            if (res.status === 200) {
                setIsOkay(true);
                setOpenModal(true);
                setDisableBTN(false);
                setSpinner(false);
            }
        })
        .catch((e) => {
            setSpinner(false);
            setIsOkay(false);
            setOpenModal(true);
            setDisableBTN(false);
        });
    };

    useEffect(() => {
        if (value !== null)
            setDisableBTN(false);
        else
            setDisableBTN(true);
    }, [value]);

    return(
        <Fragment>
            <MyModal closeModal={props.closeModal} show={props.show} addCloseCross={true} onClose={props.onClose} footer={(() => { return (<></>); })()} title={""} className={`${Styles.myModal}`}>
                {
                    spinner ?
                        <div className={`${Styles.spinnerHolder}`}>
                            <LoadingScreen />
                        </div>
                        :null
                }
                <Form className={`${Styles.formHolder}`}>
                    <Fragment>
                        <FindUser setUserID={setValue} queryParams={{carID: props.carID, scope: "client"}}/>
                    </Fragment>
                    <Button disabled={disableBTN} onClick={handleSendCarToClient}>{translator.decode("dynamic.send")}</Button>
                </Form>
            </MyModal>
            <ModalConfirmation show={openModal} onClose={() => {setOpenModal(false); setIsOkay(false);}} closeModal={() => {setOpenModal(false); setIsOkay(false);}} isOkay={isOkay} />
        </Fragment>
    )
}

export default ModalSearchUser;
