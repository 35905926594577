/*
 * File: HomeService.ts
 * Project: autobuy-back-office
 * File Created: Tuesday, 26th September 2023 03:45:15
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 27th September 2023 09:27:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import RouterPathResolver from "Config/Router/RouterPathResolver";
import CarService from "Services/Car/CarService";
import CarrierService from "Services/Carrier/CarrierService";
import GroupService from "Services/GroupService/GroupService";
import OfferService from "Services/Offer/OfferService";
import PartnerService from "Services/PartnerService/PartnerService";
import ServiceGeneral from "Services/ServiceGeneral";
import UserService from "Services/Users/UserService";

class HomeService {
    public async getCarsInfo(logout: Function) {
        const carsInfo = await ServiceGeneral.manageAxiosResponse(() => {return CarService.getCarInfo() }, () => logout());
        carsInfo.title = "cars";
        carsInfo.total = carsInfo.sold + carsInfo.archived + carsInfo.selling + carsInfo.reserved;
        carsInfo.path = RouterPathResolver.pathCar.path;
        return carsInfo;
    }

    public async getUsersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse(() => {return UserService.getUsersInfo()}, () => logout());
    }

    public async getGroupsInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse(() => {return GroupService.getGroupsInfo()}, () => logout());
    }

    public async getPartnersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse(() => {return PartnerService.getPartnersInfo()}, () => logout());
    }

    public async getCarriersInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse(() => {return CarrierService.getCarriersInfo()}, () => logout());
    }

    public async getOfferInfo(logout: Function) {
        return ServiceGeneral.manageAxiosResponse(() => {return OfferService.getOfferInfo()}, () => logout());
    }

}

export default new HomeService();
