/*
 * File: AddOfferOnCar.tsx
 * Project: autobuy-back-office
 * File Created: Monday, 9th October 2023 10:29:33
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 22nd January 2024 04:58:24
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Button, FormControlLabel, TextField, Checkbox } from "@mui/material";
import translator from "MultiLanguage/TranslationWrapper";
import CarService from "Services/Car/CarService";
import UserService from "Services/Users/UserService";
import Styles from "Content/AddOfferOnCar/AddOfferOnCar.module.css";
import { useEffect, useRef, useState } from "react";
import HrVertical from "Content/HrVertical/HrVertical";
import FindUser from "Content/FindUser/FindUser";
import { Form } from "react-router-dom";
import { BsCheck } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import LoadingScreen from "Content/LoadingScreen/LoadingScreen";

const AddOfferOnCar = (props: {carID: string, onOffer: Function, price?: number}) => {
    const [userValue, setUserValue] = useState<string>("");
    const [carOffer, setCarOffer] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [pending, setPending] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean|undefined>();
    const [isUserSelected, setIsUserSelected] = useState<boolean>(true);
    // const [giftOffer, setGiftOffer] = useState<boolean>(false);
    const self = useRef<any>({});

    useEffect(() => {
        UserService.getMyProfile().then(val => {
            self.current = val;
        }).catch((e) => {
            console.error(`Unable to pull my profile: ${JSON.stringify(e)}`);
        });
    }, []);

    useEffect(() => {
        // if (userValue !== null) {
        //     CarService.getCarByIDForSearch(props.carID).then((val) => {
        //         setCarValues(val);
        //     }).catch((e) => {
        //         console.error(`Unable to pull data for carID: ${props.carID}`);
        //     });
        // }
        if (userValue)
            setIsUserSelected(true);
        else
            setIsUserSelected(false);
    }, [userValue]);

    useEffect(() => {
        if (userValue && carOffer)
            setIsDisabled(false);
        else
            setIsDisabled(true);
    }, [userValue, carOffer]);

    const handleAddingOffer = async (offerValue: number, directAssign?: boolean) => {
        setPending(true);
        let carOffered = await CarService.makeOfferForCar(props.carID, offerValue, self.current!.id, userValue, directAssign);
        setPending(false);
        if (carOffered == null || (Array.isArray(carOffered) && carOffered.length == 0))
            setSuccess(false);
        else
            setSuccess(true);
        return carOffered;
    };

    return (
        <div>
            <Form className={`${Styles.carOffers}`} onSubmit={(e) => {{e.preventDefault(); return props.onOffer(async () => {return await handleAddingOffer(parseInt(carOffer))})}}}>
                <FindUser setUserID={setUserValue} queryParams={{carID: props.carID, scope: "client"}}/>
                {/* <FormControlLabel control={<Checkbox checked={giftOffer} onClick={() => setGiftOffer(!giftOffer)}/>} label={translator.decode("dynamic.gift")} /> */}
                <HrVertical />
                <TextField type="number" size="small" value={carOffer} onChange={(e) => setCarOffer(parseInt(e.target.value) < 1 ? "1" : e.target.value)} label={translator.decode(`offer.table.headers.Value`)}/>
                <Button type="submit" disabled={isDisabled} className={`${Styles.carOfferButton}`} variant="contained">{translator.decode(`car.pricing.atPrice`)}</Button>
                {
                    props.price ?
                    <>
                        <HrVertical />
                        <Button disabled={!isUserSelected && pending == false} className={`${Styles.carOfferButtonAlt}`} variant="contained" onClick={() => props.onOffer(async () => {return await handleAddingOffer(props.price!)})}>{<p className="m-0">{translator.decode(`car.pricing.makeOffer`)} {props.price}</p>}</Button>
                    </>
                    : null
                }
            </Form>
            <div className="d-flex gap-3">
                {
                    pending ?
                        <LoadingScreen width="2rem" height="2rem" borderWidth="2px"/>
                    : null
                }
                {
                    !pending && success !== undefined ?
                    (success === true ?
                        <BsCheck color="limegreen" size={"2rem"} />
                        : <ImCross color="crimson" />
                    ) : null
                }
            </div>
        </div>
    );
};

export default AddOfferOnCar;
