/*
 * File: CompanySelection.tsx
 * Project: autobuy-back-office
 * File Created: Tuesday, 5th September 2023 05:30:36
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Friday, 15th September 2023 03:55:26
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import translator from "MultiLanguage/TranslationWrapper";
import CompanyService from "Services/CompanyService/CompanyService";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Styles from "Content/Contact/CompanyForm/CompanyForm.module.css";
import { Col } from "react-bootstrap";
import { CompanyIntel } from "Type/company.type";

const CompanySelection = forwardRef((props: {onUpdate: Function}, ref) => {
    const [companies, setCompanies] = useState<CompanyIntel[]>([]);
    const [company, setCompany] = useState<CompanyIntel>({});

    useEffect(() => {
        CompanyService.getAutobuyCompanies().then((val => {
            setCompanies(val);
        })).catch((reason) => {
            console.error(`Unable to load the company's entreprises: ${reason.message}`);
        })
    }, []);

    useImperativeHandle(ref, () => {
        props.onUpdate();
        return {id: company.id};
    }, [company]);

    const handleCompanyChange = (event: SelectChangeEvent<string>) => {
        let tmpComp = companies.find((val) => val.id === event.target.value!.toString())!;
        setCompany(tmpComp);
    };

    return (
        <FormControl size="small" className={`${Styles.formfield}`}>
            <InputLabel required className={`${Styles.fieldLabel}`} id="select-company-label">{translator.decode("users.company")}</InputLabel>
            <Col>
                <Select required className={`${Styles.select}`} label={translator.decode("users.company")} labelId={"select-company-label"} value={company.id ?? ""} onChange={handleCompanyChange}>
                    {
                        companies.map((val) => <MenuItem key={val.id} value={val.id}>{val.Name}</MenuItem>)
                    }
                </Select>
            </Col>
        </FormControl>
    )
});

export default CompanySelection;
